import { createRouter, createWebHashHistory } from 'vue-router';

// TODO 404页面兼容
const routes = [
    { 
        path: '/', 
        component: () => import('@/views/sign/SendScan.vue'), // 扫码按钮页面
    },
    { 
        path: '/scan', 
        component: () => import('@/views/sign/CameraScan.vue'), // 扫码页面
    },
    { 
        path: '/sendSign', 
        component: () => import('@/views/sign/SendSign.vue'), // 货签单信息
        meta: {
            charset: 'utf-8',
            name: 'viewport',
            content: 'width=794, initial-scale=0.5'
        }
    },
    // { 
    //     path: '/elecSign', 
    //     component: () => import('@/views/sign/ElecSign.vue'), // 签字页面
    // },
]

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})


//导航守卫、路由守卫、路由拦截
router.beforeEach((to,from,next) =>{
    //验证token,只有存在token的时候，才能跳转到内容页
    if(to.meta.title){
        document.title = to.meta.title
    }
    
    next();
})

// const handleRoutes = (menus, parnetNodeName = null) => {
//     let routers = [];
//     for(const menu of menus) {
//         if(menu.perType == 'menu') {
//             let names = menu.perUrl.split('/').filter(i => i);
//             let componentPath = '';
//             let componetName = '';
//             for(var i = 0; i < names.length; i ++) {
//                 componetName += names[i].slice(0,1).toUpperCase() + names[i].slice(1);
//                 if(typeof(names[i + 1]) != 'undefined' && names[i + 1] != null && names[i + 1] != '' && names[i] != '') {
//                     componentPath += names[i] + '/';
//                 } else {
//                     componentPath += names[i].slice(0,1).toUpperCase() + names[i].slice(1);
//                 }
//             }
//             let node = {
//                 name: componetName, 
//                 path: menu.perUrl,
//                 meta: {
//                     title: menu.perName,
//                 },
//                 component: () => import(`@/views/${componentPath}.vue`),
//                 // component: (resolve) =>  require([`@/views/${componentPath}.vue`], resolve),
//             }
//             if(parnetNodeName != null) {
//                 router.addRoute(parnetNodeName, node);
//             } else {
//                 router.addRoute(node);
//             }
//             if(typeof(menu.children) != 'undefined' && menu.children != null && menu.children.length > 0) {
//                 node.children = handleRoutes(menu.children, componetName);
//             }
//             routers.push(node);
//         }
//     }
//     // 添加本地默认路由
//     return routers;
// }

export default router;