import { createApp } from 'vue'
import App from './App.vue'
import Router from './routers/index';
import vueEsign from 'vue-esign';
import getPdf from '@/utils/htmlToPdf'
import moment from 'moment/moment';
import 'ant-design-vue/dist/reset.css';
import Antd from 'ant-design-vue';
import { message, Modal  } from 'ant-design-vue';

const app = createApp(App);

app.use(vueEsign);
app.use(Router);
app.use(Antd);
app.config.globalProperties.$getPdf = getPdf.getPdf;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$message = message;
app.config.globalProperties.$modal = Modal;

app.mount('#app');
 