<template>
  <a-config-provider :locale="locale">
    <view class="container">
      <router-view></router-view>
    </view>
    
    <!-- 请求位置弹窗 -->
    <a-modal v-model:open="getLocationDialog.show" title="获取位置信息">
      <template #footer>
        <a-button key="back" @click="notAllowed">拒绝</a-button>
        <a-button key="submit" type="primary" @click="getLocation">Submit</a-button>
      </template>
      申请获取您的位置信息用于展示回签地址
    </a-modal>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
            getLocationDialog: {
                show: false,
            }
    }
  },
  created() {
    this.getLocationDialog.show = true;
  },
  methods: {
        getLocation() {
            this.getLocationDialog.show = false;
            // this.spinning.spinning = true;
            // this.spinning.tip = '获取位置信息中';
            navigator.geolocation.getCurrentPosition(this.getPositionSuccess, this.getPositionError);
        }, 
    getPositionSuccess(position) {
        console.log(position);
        this.latitude = position.coords.latitude; // 纬度
        this.longitude = position.coords.longitude; // 经度
        // 调用腾讯地图API获取地理位置
    },
    getPositionError(err) {
        console.log(err);
    },
  }
}
</script>

<style>
  body {
    margin: 0;
    font-size: 14px;
  }
  .container{
    
  }

</style>
